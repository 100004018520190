// src/components/RecentSignatures.js
import React, { useEffect, useState } from 'react';
import { Box, Typography } from '@mui/material';

const RecentSignatures = () => {
  const [signatures, setSignatures] = useState([]);

  useEffect(() => {
    const fetchRecentSignatures = async () => {
      if (process.env.NODE_ENV === 'development') {
        // Utiliser des données fictives en mode développement
        setSignatures([
          { firstName: 'Jean', lastName: 'Dupont', createdAt: new Date() },
          { firstName: 'Marie', lastName: 'Durand', createdAt: new Date(new Date().getTime() - 600000) }, // 10 minutes ago
          { firstName: 'Luc', lastName: 'Martin', createdAt: new Date(new Date().getTime() - 3600000) }, // 1 hour ago
          { firstName: 'Sophie', lastName: 'Lemoine', createdAt: new Date(new Date().getTime() - 7200000) } // 2 hours ago
        ]);
      } else {
        const BASE_URL = 'https://backend.antideforestation.fr/p1';
        try {
          const response = await fetch(`${BASE_URL}/users/getrecentusers`);
          const data = await response.json();
          setSignatures(data.users);
        } catch (error) {
          console.error('Error fetching recent signatures:', error);
        }
      }
    };

    fetchRecentSignatures();
  }, []);

  const timeElapsed = (date) => {
    const now = new Date();
    const past = new Date(date);
    const diffMs = now - past;
    const diffDays = Math.floor(diffMs / 86400000);
    const diffHrs = Math.floor((diffMs % 86400000) / 3600000);
    const diffMins = Math.round(((diffMs % 86400000) % 3600000) / 60000);
    return diffDays > 0
      ? `${diffDays} jour${diffDays > 1 ? 's' : ''}`
      : diffHrs > 0
      ? `${diffHrs} heure${diffHrs > 1 ? 's' : ''}`
      : `${diffMins} minute${diffMins > 1 ? 's' : ''}`;
  };

  return (
    <Box mt={2}>
      <Box mt={2} style={{ fontFamily: 'Inter, Arial' }}>
        {signatures.slice(0, 3).map((signature, index) => ( // Limiter à 3 signatures
          <Typography key={index} variant="body1" sx={{ mb:1, display: 'flex', flexDirection: 'row', justifyContent: 'space-between'  }}>
            <Box sx={{ fontWeight:'bold' }}>{signature.firstName} {signature.lastName.charAt(0)}.</Box>
            <Box>a signé il y a {timeElapsed(signature.createdAt)}</Box>
          </Typography>
        ))}
      </Box>
    </Box>
  );
};

export default RecentSignatures;
