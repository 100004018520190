// src/components/HeroSection.js
import React from 'react';
import { Box, Typography } from '@mui/material';

const HeroSection = () => {
  return (
    <Box textAlign="center" mt={8} mb={8}>
      <Typography variant="h1" style={{ fontFamily: 'Oswald, Arial', fontWeight: 'bold', textTransform: 'uppercase' }}>
        VOTRE SIGNATURE SAUVE LA FORÊT
      </Typography>
      <Typography variant="subtitle1" sx={{ fontFamily: 'Inter, Arial', fontWeight:'bold', backgroundColor:'rgb(255, 230, 214)', width:'fit-content', margin:'10px auto', px:2, py:1, borderRadius:'5px' }}>
        10 SIGNATURES = 1M² DE FORÊT FINANCÉ
      </Typography>
    </Box>
  );
};

export default HeroSection;
