// src/App.js
import React from 'react';
import { ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import theme from './theme';
import PetitionPage from './views/PetitionPage';
import PrivacyPage from './views/PrivacyPage';
import SharePage from './views/SharePage'; 
import ThankYouPage from './views/ThankYouPage'; 

function App() {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Router>
        <Routes>
        <Route path="/" element={<PetitionPage />} />
        <Route path="/partage" element={<SharePage />} /> 
        <Route path="/merci" element={<ThankYouPage />} /> 
        <Route path="/privacy" element={<PrivacyPage />} /> 
        </Routes>
      </Router>
    </ThemeProvider>
  );
}

export default App;
