// src/components/SupportSection.js
import React from 'react';
import { Box, Typography, Grid, Card, CardContent, CardMedia, Button } from '@mui/material';

const SupportSection = () => {
  return (
    <Box mt={6}>
      <Typography variant="h4" sx={{ fontFamily: 'Oswald, Arial', fontWeight: 'bold', mb: 2 }}>
        AVEC LE SOUTIEN DE :
      </Typography>
      <Grid container spacing={4} justifyContent="center" textAlign="center">
        <Grid item xs={12} md={6}>
          <Card elevation={0} sx={{ border: '1px solid #DDD' }}>
            <CardMedia
              component="img"
              alt="Kalaweit"
              image="/img/kalaweit.avif"
              sx={{ height: '100px', objectFit: 'contain', margin: 'auto', pt: 2 }}
            />
            <CardContent>
              <Typography variant="body2" color="text.secondary" style={{ fontFamily: 'Inter, Arial' }}>
                L'association Kalaweit se bat depuis plus de 25 ans en Indonésie pour protéger la forêt avec le soutien des populations locales.
              </Typography>
              <Button
                variant="contained"
                style={{ fontFamily: 'Inter, Arial', marginTop: '10px', backgroundColor: '#eee' }}
                onClick={() => window.open('https://kalaweit.org', '_blank', 'noopener noreferrer')}
              >
                En savoir plus
              </Button>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} md={6}>
          <Card elevation={0} sx={{ border: '1px solid #DDD' }}>
            <CardMedia
              component="img"
              alt="Papa Outang"
              image="/img/papaoutang.svg"
              sx={{ height: '100px', objectFit: 'contain', margin: 'auto', pt: 2 }}
            />
            <CardContent>
              <Typography variant="body2" color="text.secondary" style={{ fontFamily: 'Inter, Arial' }}>
                Papa Outang est une pâte à tartiner liée à vie à l'association Kalaweit. Les ingrédients sont bios, équitables, et la recette est vegan.
              </Typography>
              <Button
                variant="contained"
                style={{ fontFamily: 'Inter, Arial', marginTop: '10px', backgroundColor: '#eee' }}
                onClick={() => window.open('https://papaoutang.fr', '_blank', 'noopener noreferrer')}
              >
                En savoir plus
              </Button>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Box>
  );
};

export default SupportSection;
