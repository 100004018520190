// src/components/Footer.js
import React from 'react';
import { Box, Typography, Link } from '@mui/material';
import { ReactComponent as FooterLogo } from '../footer.svg';

const Footer = () => {
  return (
    <Box 
      display="flex" 
      flexDirection="column" 
      alignItems="center" 
      sx={{ mt:8, mb:6 }}
    >
      <Box sx={{ border:'1px solid #DDD', py:2, px:4, mb:1, borderRadius:'5px' }}>
        <FooterLogo width={250} height={100} />
      </Box>
      <Typography variant="body2" style={{ marginTop: '8px' }}>
        <Link href="/privacy" style={{ marginRight: '16px', color:'#555', textDecoration:'none', textTransform:'uppercase' }}>Confidentialité</Link>
        <Link href="mailto:latribu@papaoutang.fr" style={{ color:'#555', textDecoration:'none', textTransform:'uppercase' }}>Contact</Link>
      </Typography>
    </Box>
  );
};

export default Footer;
