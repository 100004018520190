// src/components/ShareModule.js
import React, { useState } from 'react';
import { Box, Typography, Button, TextField, Grid, Alert, Snackbar } from '@mui/material';
import { useLocation, Link } from 'react-router-dom';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { ReactComponent as Logo } from '../logo.svg'; // Assurez-vous que le logo est importé correctement

const ShareModule = () => {
  const [alertOpen, setAlertOpen] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  
  const location = useLocation();
  const isSharePage = location.pathname === '/partage';

  const affiliateUrl = "https://antideforestation.fr/";
  const message = `Salut, je viens de signer une pétition qui sauve la forêt (pour de vrai : 10 signatures = 1 mètre carré de forêt protégé) et j'ai besoin de ton aide ! Si tu la signes à ton tour, mon don sera multiplié par 10 ! Ça ne prend que 10 secondes. Je compte sur toi 🤝❤️ ${affiliateUrl}`;

  const copyToClipboard = (text, alertText) => {
    navigator.clipboard.writeText(text).then(() => {
      setAlertMessage(alertText);
      setAlertOpen(true);
    });
  };

  const shareLinks = [
    { id: 'share_SMS', label: 'SMS', icon: '/img/message_icon.svg', href: `sms:?&body=${message}` },
    { id: 'share_mail', label: 'Mail', icon: '/img/mail_icon.svg', href: `mailto:?&subject=Aide moi à sauver la forêt&body=${message}` },
    { id: 'share_whatsapp', label: 'Whatsapp', icon: '/img/whatsapp_icon.svg', href: `https://wa.me/?text=${message}` },
    { id: 'share_messenger', label: 'Messenger', icon: '/img/messenger_icon.svg', href: `fb-messenger://share/?link=${affiliateUrl}` },
    { id: 'share_facebook', label: 'Facebook', icon: '/img/facebook_icon.svg', href: `https://www.facebook.com/sharer/sharer.php?u=${affiliateUrl}`, target: '_blank' },
    { id: 'share_linkedin', label: 'Linkedin', icon: '/img/linkedin_icon.svg', href: `https://www.linkedin.com/shareArticle?mini=true&url=${affiliateUrl}`, target: '_blank' },
    { id: 'share_telegram', label: 'Telegram', icon: '/img/telegram_icon.svg', href: `tg://msg_url?url=${affiliateUrl}`, target: '_blank' },
    { id: 'share_twitter', label: 'Twitter', icon: '/img/twitter_icon.svg', href: `https://twitter.com/intent/tweet?url=${affiliateUrl}&text=${message}`, target: '_blank' }
  ];

  return (
    <Box py={5}>
      <Box textAlign="center" mb={4}>
        <Logo width={220} />
      </Box>
      <Box
        bgcolor="white"
        px={4}
        py={6}
        mx="auto"
        maxWidth="600px"
        textAlign="center"
        borderRadius="8px"
        border="1px solid #DDD"
      >
        {isSharePage ? (
          <>
            <Typography variant="h2" gutterBottom>
              Confirmez votre mail pour valider votre signature
            </Typography>
            <Typography variant="body1" mb={2} gutterBottom>
              Invitez vos amis et votre famille à rejoindre le défi :
            </Typography>
          </>
        ) : (
          <>
            <Typography variant="h2" gutterBottom>
              VOUS VENEZ DE SAUVER 0.1M² DE LA DÉFORESTATION !
            </Typography>
            <Typography variant="body1" mb={2} gutterBottom>
              Multipliez votre don par 10 en faisant signer un ami :
            </Typography>
          </>
        )}
        <Box display="flex" justifyContent="center" mb={4}>
          <TextField
            value={affiliateUrl}
            InputProps={{
              readOnly: true,
            }}
            variant="outlined"
            className="copy-link-input"
            onClick={() => copyToClipboard(affiliateUrl, 'Lien copié !')}
            sx={{ flexGrow: 1, marginRight: 1 }}
          />
          <Button className="copy-link-button" onClick={() => copyToClipboard(affiliateUrl, 'Lien copié !')} >
            Copier votre lien
          </Button>
        </Box>
        <Box onClick={() => copyToClipboard(message, 'Message copié !')} fullWidth style={{ background: 'white', display:'contents', cursor:'pointer' }}>
          <Typography style={{ borderRadius: '5px 5px 0 0', textTransform: 'uppercase', margin: '10px 0 0', fontWeight:'bold' }}>
            Cliquer pour copier le message :
          </Typography>
          <Typography variant='body1' style={{ padding: '15px', fontSize: '14px', textAlign: 'center' }}>
            {message}
          </Typography>
        </Box>
        <Grid container mt={1} spacing={1.5} justifyContent="center">
        {shareLinks.map((item) => (
            <Grid item xs={6} sm={6} md={6} lg={6} key={item.id}>
            <Button
                className="button-share-timer"
                href={item.href}
                target={item.target || "_blank"}
                fullWidth
                style={{ color: '#012f2b', backgroundColor: '#fff', border: '1px solid #ddd', borderRadius: '5px', display: 'flex', alignItems: 'center', justifyContent: 'center', padding: '10px', textTransform: 'uppercase', fontWeight: '500', fontSize: '17px' }}
            >
                <img src={item.icon} alt="" className="image-2" style={{ height: '18px', marginRight: '5px' }} />
                {item.label}
            </Button>
            </Grid>
        ))}
        </Grid>
      </Box>
      <Box textAlign="center" mt={4}>
        <Button
          style={{ backgroundColor: '#eee' }}
          variant="contained"
          startIcon={<ArrowBackIcon />}
          component={Link}
          to="/"
        >
          Retour
        </Button>
      </Box>
      <Snackbar open={alertOpen} autoHideDuration={3000} onClose={() => setAlertOpen(false)}>
        <Alert onClose={() => setAlertOpen(false)} severity="success" sx={{ width: '100%' }}>
          {alertMessage}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default ShareModule;
