// src/components/Header.js
import React from 'react';
import { AppBar, Toolbar, Button, Box, useMediaQuery, useTheme } from '@mui/material';
import { ReactComponent as Logo } from '../logo.svg';

const Header = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const handleScrollToForm = () => {
    const formElement = document.getElementById('petition-form');
    if (formElement) {
      formElement.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <AppBar position="static" style={{ backgroundColor: '#ffffff', boxShadow: 'none', borderBottom: '1px solid #dddddd' }}>
      <Toolbar style={{ padding: '12px 0', justifyContent: isMobile ? 'center' : 'space-between' }}>
        <Box display="flex" alignItems="center">
          <Logo width={220} />
        </Box>
        {!isMobile && (
          <Button size="large" variant="contained" style={{ backgroundColor: '#FFAD81', color: '#002d27', fontWeight: 'bold' }} onClick={handleScrollToForm}>
            Je signe
          </Button>
        )}
      </Toolbar>
    </AppBar>
  );
};

export default Header;
