// src/components/PetitionForm.js
import React, { useState, useEffect } from 'react';
import { Box, Typography, TextField, Button, Checkbox, FormControlLabel, Skeleton, Grid } from '@mui/material';

const PetitionForm = () => {
  const [form, setForm] = useState({
    firstName: '',
    lastName: '',
    zipCode: '',
    email: '',
    optIn: true,
  });

  const [error, setError] = useState('');
  const [sqmSaved, setSqmSaved] = useState(0);
  const [signatories, setSignatories] = useState(0);
  const [nextThreshold, setNextThreshold] = useState(10);
  const [loading, setLoading] = useState(true); // État de chargement
  const BASE_URL = 'https://backend.antideforestation.fr/p1';

  useEffect(() => {
    const fetchStatus = async () => {
      if (process.env.NODE_ENV === 'development') {
        // Utiliser des données fictives en mode développement
        setSqmSaved(1200);
        setSignatories(100);
        setNextThreshold(1500);
        setLoading(false); // Arrêter le chargement après avoir défini les données fictives
      } else {
        try {
          const response = await fetch(`${BASE_URL}/users/getstatus/`);
          const data = await response.json();
          setSqmSaved(data.totalSqmSaved);
          setSignatories(data.totalVerifiedSignatures);
          const thresholds = [10, 100, 250, 500, 1000, 2500, 5000, 10000, 20000];
          const next = thresholds.find(threshold => threshold > data.totalSqmSaved);
          setNextThreshold(next || data.totalSqmSaved);
          setLoading(false); // Arrêter le chargement après avoir défini les données réelles
        } catch (error) {
          console.error('Error fetching status:', error);
          setLoading(false); // Arrêter le chargement même en cas d'erreur
        }
      }
    };

    fetchStatus();
  }, [BASE_URL]);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setForm({
      ...form,
      [name]: type === 'checkbox' ? checked : value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const params = new URLSearchParams(window.location.search);
    const teamToken = params.get("team");

    try {
      const response = await fetch(`${BASE_URL}/sign${teamToken ? '/' + teamToken : ''}`, {
        method: 'POST',
        headers: {
          'Content-type': 'application/json; charset=utf-8',
          'Origin': 'https://antideforestation.fr/'
        },
        body: JSON.stringify({
          firstName: form.firstName,
          lastName: form.lastName,
          email: form.email,
          postcode: form.zipCode,
          optIn: form.optIn ? 'true' : 'false',
          params: params.toString(),
        }),
      });

      const data = await response.json();
      if (response.status > 201) {
        setError(data.message);
      } else {
        localStorage.setItem("userId", data.userId);
        localStorage.setItem("firstName", data.firstName);
        localStorage.setItem("shareUrl", data.affiliateUrl);
        localStorage.setItem("alreadyExists", data.alreadyExists);
        window.location.href = `https://www.antideforestation.fr/partage?team=${data.userId}`;
      }
    } catch (error) {
      setError('Erreur lors de l\'envoi du formulaire');
    }
  };

  return (
    <Box component="form" onSubmit={handleSubmit} p={3} borderRadius="5px" bgcolor="rgb(255, 230, 214)">
      {loading ? (
        <>
          <Skeleton variant="text" width={210} height={80} />
          <Skeleton variant="text" width={210} height={30} />
          <Skeleton variant="text" width={210} height={30} sx={{ mb:2 }}/>
        </>
      ) : (
        <>
          <Typography variant="h4" style={{ fontFamily: 'Oswald, Arial', fontWeight: 'bold', marginBottom: '5px' }}>
            <span id="signature-form-title-counter" data-counter={sqmSaved}>{sqmSaved}</span> M² DÉJÀ SAUVÉS
          </Typography>
          <Typography variant="body1" style={{ fontFamily: 'Inter, Arial', marginBottom: '15px' }}>
            Grâce à <span id="signature-form-subtitle-counter" data-counter={signatories}>{signatories}</span> signataires
          </Typography>
          <Typography variant="body1" style={{ fontWeight: 'bold', marginBottom: '15px' }}>
            Aidez-nous à atteindre les <span id="next-threshold">{nextThreshold}</span> m² :
          </Typography>
        </>
      )}
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <TextField fullWidth label="Prénom" name="firstName" variant="outlined" value={form.firstName} onChange={handleChange} required />
        </Grid>
        <Grid item xs={6}>
          <TextField fullWidth label="Nom" name="lastName" variant="outlined" value={form.lastName} onChange={handleChange} required />
        </Grid>
      </Grid>
      <TextField sx={{ mt: 1.5 }} fullWidth label="Code postal" name="zipCode" variant="outlined" value={form.zipCode} onChange={handleChange} required />
      <TextField sx={{ mt: 1.5 }} fullWidth label="Adresse mail" name="email" variant="outlined" value={form.email} onChange={handleChange} required />
      <FormControlLabel
        control={<Checkbox name="optIn" checked={form.optIn} onChange={handleChange} />}
        label="Recevoir des nouvelles de la forêt"
        sx={{ '& .MuiFormControlLabel-label': { fontSize: '0.875rem' } }}
      />
      <Button size="large" id="signature_form_submit" variant="contained" fullWidth style={{ backgroundColor: '#FFAD81', color: '#002d27', fontWeight: 'bold', marginTop: '0px' }} type="submit">
        Je sauve la forêt
      </Button>
      {error && (
        <Typography id="signature_form_error" variant="body2" color="error" style={{ marginTop: '10px' }}>
          {error}
        </Typography>
      )}
    </Box>
  );
};

export default PetitionForm;
