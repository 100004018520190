// src/views/SharePage.js
import React from 'react';
import { Container } from '@mui/material';
import ShareModule from '../components/ShareModule';

const SharePage = () => {
  return (
    <Container maxWidth="lg">
      <ShareModule />
    </Container>
  );
};

export default SharePage;
