// src/components/PetitionDetails.js
import React from 'react';
import { Box, Typography, Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const PetitionDetails = () => {
  return (
    <Box mt={0}>
      <img src="img/foret.jpg" alt="Foret" style={{ width: '100%', height: 'auto', borderRadius:'5px', marginBottom:'20px' }} />
      <Typography variant="h2" style={{ fontFamily: 'Oswald, Arial', fontWeight: 'bold' }}>
        AIDEZ-NOUS À SAUVER 10 000M² DE FORÊT
      </Typography>
      <Typography variant="body1" sx={{ fontFamily: 'Inter, Arial', fontWeight:'bold', textAlign:'center', backgroundColor:'rgb(255, 230, 214)', width:'fit-content', margin:'10px auto', px:3, py:2, borderRadius:'5px' }}>
        Pour 10 signatures, Papa Outang s'engage à donner 12 centimes à l'association Kalaweit, soit 1m² sauvé pour toujours de la déforestation.
      </Typography>
      
      <Typography variant="body1" style={{ fontFamily: 'Inter, Arial', marginTop: '1rem' }}>
        D’habitude, lorsqu’on vous parle de signer une pétition, c’est toujours pour faire pression sur une entreprise ou sur le gouvernement. L’impact d’une pétition est, à chaque fois, plus ou moins mesurable. On se rappelle toutes et tous en avoir signé des dizaines, sans pour autant avoir de résultats concrets.
      </Typography>
      <Typography variant="body1" style={{ fontFamily: 'Inter, Arial', marginTop: '1rem' }}>
        Aujourd’hui, on vous propose de changer le rapport de force : à chaque signature, un don sera fait à l’association Kalaweit pour agrandir ses réserves naturelles en Indonésie, des forêts tropicales qui abritent des centaines d'espèces menacées d'extinction.
      </Typography>
      <Typography variant="body1" style={{ fontFamily: 'Inter, Arial', marginTop: '1rem' }}>
        Vous signez, nous donnons. C’est aussi simple que ça ! Alors, citoyens et citoyennes, signez pour sauver la forêt, et partagez-la avec tous vos proches. Pour une fois c’est VOUS qui avez le pouvoir.
      </Typography>
      
      <Box sx={{my:2}}>
        <Accordion sx={{ boxShadow: 'none', mb:1, pt:1}}>
          <AccordionSummary style={{ padding:'0'}} expandIcon={<ExpandMoreIcon />}>
            <Typography style={{ fontFamily: 'Inter, Arial', fontWeight:'bold' }}>QUI FINANCE L'ACHAT DES MÈTRES CARRÉS DE FORÊT ?</Typography>
          </AccordionSummary>
          <AccordionDetails style={{ padding:'0'}}>
            <Typography style={{ fontFamily: 'Inter, Arial' }}>
              L'entreprise à mission Papa Outang, étroitement liée à l'association Kalaweit, finance cette pétition. Papa Outang est une pâte à tartiner dont la mission est de sauver la forêt des orangs-outangs.
              <br /><br />
              Plutôt que de dépenser des milliers d'euros dans des publicités payantes sur Facebook ou Instagram pour faire connaître la marque, Papa Outang a choisi de financer l'association Kalaweit grâce à cette pétition.
              <br /><br />
              Papa Outang s'engage à reverser 12 centimes d'euro toutes les 10 signatures, sauf dans le cas où le signataire invite quelqu'un d'autre à signer via son lien d'invitation : dans ce cas, son don est multiplié par 10.
              <br /><br />
              À Bornéo et à Sumatra, l'association Kalaweit mène des projets de conservation. Pour protéger la forêt, elle travaille en collaboration avec les populations locales pour créer des réserves naturelles. En moyenne, le coût d'un hectare de forêt est de 1 200 €, ce qui équivaut à 12 € pour 100 m², soit 1,2 € pour 10 m², et donc 12 centimes pour 1 m².
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion sx={{ boxShadow: 'none', mb:1, pt:1}}>
          <AccordionSummary style={{ padding:'0'}} expandIcon={<ExpandMoreIcon />}>
            <Typography style={{ fontFamily: 'Inter, Arial', fontWeight:'bold' }}>À QUOI SERVIRA L'ARGENT RÉCOLTÉ ?</Typography>
          </AccordionSummary>
          <AccordionDetails style={{ padding:'0'}}>
            <Typography style={{ fontFamily: 'Inter, Arial' }}>
              Avec cette pétition, nous souhaitons aider l’association Kalaweit à finir d'acquérir 1500 hectares d’une forêt cernée par les industriels de l’huile de palme : la réserve de Dulan.
              <br /><br />
              C’est une forêt primaire que les villageois souhaitent céder à Kalaweit. Elle est un refuge pour les animaux, notamment pour une centaine d’orangs-outangs ayant fui la déforestation. La forêt appartient aux familles du village de Butong, qui pêchent dans le lac.
              <br /><br />
              Si la forêt est dégradée par les activités humaines, le lac sera immanquablement pollué et les activités de pêche seront compromises. Pour conserver cette activité essentielle et continuer à vivre dans leur village, les villageois veulent que cette forêt soit protégée par Kalaweit.
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion sx={{ boxShadow: 'none', mb:1, pt:1}}>
          <AccordionSummary style={{ padding:'0'}} expandIcon={<ExpandMoreIcon />}>
            <Typography style={{ fontFamily: 'Inter, Arial', fontWeight:'bold' }}>POURQUOI LES POPULATIONS LOCALES CÈDENT LEURS TERRES À KALAWEIT ?</Typography>
          </AccordionSummary>
          <AccordionDetails style={{ padding:'0'}}>
            <Typography style={{ fontFamily: 'Inter, Arial' }}>
              Il y a encore de nombreuses zones de forêt intactes et riches en biodiversité à Bornéo et à Sumatra. En préservant ces forêts, Kalaweit offre un habitat sûr à la faune sauvage tout en luttant contre la déforestation.
              <br /><br />
              Les populations qui vivent près des forêts commencent à réaliser l'importance de protéger leur environnement. Elles constatent la disparition des forêts, remplacées par des plantations d'huile de palme, et une dégradation de leur qualité de vie (pollutions des sols, exploitation humaine…)
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion sx={{ boxShadow: 'none', mb:1, pt:1}}>
          <AccordionSummary style={{ padding:'0'}} expandIcon={<ExpandMoreIcon />}>
            <Typography style={{ fontFamily: 'Inter, Arial', fontWeight:'bold' }}>COMMENT L’ASSOCIATION KALAWEIT PROTÈGE LES RÉSERVES NATURELLES ?</Typography>
          </AccordionSummary>
          <AccordionDetails style={{ padding:'0'}}>
            <Typography style={{ fontFamily: 'Inter, Arial' }}>
              Kalaweit organise régulièrement des réunions avec le maire, le chef de village et les familles afin de mettre en place la protection de la zone et d'assurer un suivi.
              <br /><br />
              Les villageois conservent toujours l'accès aux terres pour leurs activités traditionnelles telles que la cueillette de fruits, la collecte de rotin et de latex, mais ils ne sont pas autorisés à abattre des arbres ou à chasser.
              <br /><br />
              Des panneaux de délimitation sont installés autour de la zone protégée tous les 20/30 mètres. Des postes de garde sont positionnés aux endroits stratégiques tels que les points d'accès, les points d'eau et les zones les plus élevées.
              <br /><br />
              La surveillance des forêts est assurée par des patrouilles quotidiennes effectuées par des gardes forestiers recrutés dans les villages partenaires. Ces emplois renforcent nos liens avec les villageois. Les patrouilles sont effectuées à cheval et par voie aérienne (drones, paramoteurs) afin de repérer toute activité suspecte.
            </Typography>
          </AccordionDetails>
        </Accordion>
      </Box>
    </Box>
  );
};

export default PetitionDetails;
